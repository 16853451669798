import { Injectable } from '@angular/core';
import { OnlyAviaPackage, OnlyHotelPackage, PackageFlight, PackageHotel, TourPackage } from '@appTypes/api.types';
import { ApiErrorResponse } from '@core/interceptors/api.interceptor';
import { DataLoadStatusModel } from '@core/store/store.models';
import { StoreService } from '@core/store/store.service';
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';
import { insertItem, patch, removeItem } from '@ngxs/store/operators';
import { processCheckoutItems } from '@pages/checkout-page/checkout.utils';
import { CheckoutApiService } from '@pages/checkout-page/services/checkout-api.service';
import { ConfirmService } from '@shared/modules/confirm/confirm.service';
import { inRange, uniqueId } from 'lodash-es';
import { catchError, tap, throwError } from 'rxjs';
import { AppPersistedState } from 'src/app/state/app.persisted.state';
import { AppState } from 'src/app/state/app.state';
import { AppPersistedStateModel } from '../../../../state/app.models';
import { CartItem } from '../cart.types';
import { cartAddItem, cartClear, cartRemoveItem, cartUpdateTotalPrice } from './cart.actions';
import { CartStateModel } from './cart.model';

const defaults = new CartStateModel();

@State<CartStateModel>({
  name: 'cart',
  defaults
})
@Injectable()
export class CartState {
  constructor(
    private storeService: StoreService,
    private confirmService: ConfirmService,
    private checkoutApiService: CheckoutApiService
  ) {}
  @Action(cartAddItem)
  cartAddItem({ setState, dispatch, getState }: StateContext<CartStateModel>, { payload }: cartAddItem) {
    const { items } = getState();
    const itemsCountryCodes = items
      .filter((i) => i.type === 'hotel' || i.type === 'tour')
      .map(({ data }: { data: TourPackage | OnlyHotelPackage }) => {
        return data.hotel?.countryCode;
      });
    const isDifferentCountry = itemsCountryCodes.filter(
      (countryCode) =>
        (payload.type === 'tour' || payload.type === 'hotel') &&
        countryCode !== (payload.data as TourPackage | OnlyHotelPackage).hotel?.countryCode
    ).length;
    if (isDifferentCountry) {
      this.confirmService.confirm(
        {
          title: "Cart can't be updated",
          message:
            'There is already an item from a different country. You can either select the same country or clear the basket before adding new one'
        },
        'info'
      );
    } else {
      setState(patch({ items: insertItem({ ...payload, id: uniqueId() } as CartItem, items.length) }));
      this.confirmService.confirm(
        { title: 'Cart is updated', message: 'The order has been added to the cart' },
        'success'
      );

      dispatch(new cartUpdateTotalPrice());
    }
  }
  @Action(cartRemoveItem)
  cartRemoveItem({ setState, dispatch }: StateContext<CartStateModel>, { id }: cartRemoveItem) {
    setState(patch({ items: removeItem((item) => item.id === id) }));
    dispatch(new cartUpdateTotalPrice());
  }

  @Action(cartUpdateTotalPrice, { cancelUncompleted: true })
  cartUpdateTotalPrice({ getState, patchState }: StateContext<CartStateModel>) {
    const { items } = getState();
    const activedCurrency = this.storeService.selectSnapshot(AppPersistedState.activedCurrency);
    if (!activedCurrency) return;

    patchState({ totalPriceLoadingStatus: new DataLoadStatusModel('loading') });
    let _totalPrice = 0;

    if (items.length) {
      for (const item of items) _totalPrice = item.price + _totalPrice;

      const _payload = processCheckoutItems(items);
      return this.checkoutApiService.getCheckoutFormData(_payload).pipe(
        tap((response) => {
          let totalPriceChangeReasonCode: string | null = null;
          let totalPrice: number = response?.totalPrice ?? 0;
          if (inRange(totalPrice, _totalPrice - 2, _totalPrice + 2)) {
            totalPrice = _totalPrice;
          } else {
            //at this moment known reason is only Combi tour transfers calculation
            totalPriceChangeReasonCode = 'COMBI_TOUR_TRANSFERS';
          }

          const exchangeRate = this.storeService.selectSnapshot(
            AppState.findExchangeRate(response.priceCurrency ?? 'USD', activedCurrency)
          );
          patchState({
            totalPriceChangeReasonCode,
            totalPrice: (totalPrice ?? 0) * (exchangeRate?.rate ?? 1),
            totalPriceLoadingStatus: new DataLoadStatusModel('completed')
          });
        }),
        catchError((e: ApiErrorResponse) => {
          this.confirmService.confirm(
            {
              title: "Can't calculate total price of cart",
              message: "It seems that something has gone wrong. Don't worry, our support team is ready to help you"
            },
            'error'
          );

          patchState({
            totalPriceLoadingStatus: new DataLoadStatusModel(
              'error',
              "It seems that something has gone wrong. Don't worry, our support team is ready to help you"
            )
          });
          return throwError(() => e);
        })
      );
    }
    return;
  }

  @Action(cartClear)
  cartClear({ setState }: StateContext<CartStateModel>) {
    setState({
      items: [],
      totalPrice: 0,
      totalPriceChangeReasonCode: null,
      totalPriceLoadingStatus: new DataLoadStatusModel()
    });
  }

  /* Selectors */

  @Selector([CartState])
  static itemsCount({ items }: CartStateModel) {
    return items?.length ?? 0;
  }
  @Selector([CartState])
  static items({ items }: CartStateModel) {
    return items;
  }

  @Selector([CartState])
  static hotelsFromitems({ items }: CartStateModel) {
    const hotels: PackageHotel[] = [];
    for (const item of items) {
      if (item.type === 'tour' && item.data.hotel) hotels.push(item.data.hotel);
      else if (item.type === 'hotel' && item.data.hotel) hotels.push(item.data.hotel);
    }
    return hotels;
  }

  static getHotelByPriceId(hotelPriceId: string) {
    return createSelector([CartState], ({ items }: CartStateModel) => {
      const _hotelPriceId: PackageHotel['hotelPriceId'] = hotelPriceId; // @Note - temporary solution -  backend needs change package `hotelPriceId` to string

      return (
        items.find(
          (i) =>
            (i.type === 'hotel' || i.type === 'tour') &&
            i.data.hotel?.hotelPriceId &&
            i.data.hotel.hotelPriceId === _hotelPriceId
        )?.data as TourPackage | OnlyHotelPackage
      )?.hotel;
    });
  }

  static getHotelByPriceIds(hotelPriceIds: string[]) {
    return createSelector([CartState], ({ items }: CartStateModel) => {
      const _hotelPriceIds: PackageHotel['hotelPriceId'][] = hotelPriceIds; // @Note - temporary solution -  backend needs change package `hotelPriceId` to string
      return items
        .filter(
          (i) =>
            (i.type === 'hotel' || i.type === 'tour') &&
            i.data.hotel?.hotelPriceId &&
            _hotelPriceIds.includes(i.data.hotel.hotelPriceId)
        )
        .map((e: { data: TourPackage | OnlyHotelPackage }) => e.data.hotel as PackageHotel);
    });
  }
  @Selector([CartState])
  static flightsFromItems({ items }: CartStateModel) {
    const flights: PackageFlight[] = [];
    for (const item of items) {
      if (item.type === 'tour' && item.data.flight) flights.push(item.data.flight);
      else if (item.type === 'avia' && item.data.flight) flights.push(item.data.flight);
    }
    return flights;
  }

  static getFlight(flightId: string) {
    return createSelector([CartState], ({ items }: CartStateModel) => {
      return (
        items.find(
          (i) => (i.type === 'avia' || i.type === 'tour') && i.data.flight?.id && i.data.flight?.id === flightId
        )?.data as TourPackage | OnlyAviaPackage
      )?.flight;
    });
  }

  static getFlights(flightIds: string[]) {
    return createSelector([CartState], ({ items }: CartStateModel) => {
      return items
        .filter(
          (i) => (i.type === 'avia' || i.type === 'tour') && i.data.flight?.id && flightIds.includes(i.data.flight?.id)
        )
        .map((e: { data: TourPackage | OnlyAviaPackage }) => e.data.flight as PackageFlight);
    });
  }

  @Selector([CartState, AppPersistedState])
  static totalPrice(
    { totalPrice, totalPriceLoadingStatus, totalPriceChangeReasonCode }: CartStateModel,
    { currency }: AppPersistedStateModel
  ) {
    return { totalPrice, currency, totalPriceLoadingStatus, totalPriceChangeReasonCode };
  }
}
