import { LoadCheckoutFormDataPayload } from './checkout.types';
import { CheckoutFormIn } from '@appTypes/api.types';
import { CartItem } from '@shared/modules/cart/cart.types';

export const processCheckoutItems = (
  items: CartItem[],
  payload?: LoadCheckoutFormDataPayload,
  refreshPrices = false
) => {
  const calcTourPackageRequests: CheckoutFormIn['calcTourPackageRequests'] = [];
  const calcOnlyHotelPackageRequests: CheckoutFormIn['calcOnlyHotelPackageRequests'] = [];
  const calcOnlyAviaPackageRequests: CheckoutFormIn['calcOnlyAviaPackageRequests'] = [];

  for (const item of items) {
    if (item.type === 'tour') {
      calcTourPackageRequests.push({
        refreshPrices,
        packageId: item.data.id,
        flightPassengers: item.data.flight?.passengers?.map((el) => ({
          id: el.id,
          selectedForwardBaggageCode: el.selectedForwardBaggageCode,
          selectedBackwardBaggageCode: el.selectedBackwardBaggageCode
        })),
        extraServicePriceCodes:
          (item.data.extraServices?.filter((e) => e.includedPriceCode)?.map((e) => e.includedPriceCode) as string[]) ??
          [],
        includeInsurance: item.data.insurancePrice?.includedInPackagePrice
      });
    } else if (item.type === 'hotel') {
      calcOnlyHotelPackageRequests.push({
        refreshPrices,
        packageId: item.data.id,
        extraServicePriceCodes:
          (item.data.extraServices?.filter((e) => e.includedPriceCode)?.map((e) => e.includedPriceCode) as string[]) ??
          [],
        includeInsurance: item.data.insurancePrice?.includedInPackagePrice
      });
    } else if (item.type === 'avia') {
      calcOnlyAviaPackageRequests.push({
        refreshPrices,
        packageId: item.data.id,
        flightPassengers: item.data.flight?.passengers?.map((el) => ({
          id: el.id,
          selectedForwardBaggageCode: el.selectedForwardBaggageCode,
          selectedBackwardBaggageCode: el.selectedBackwardBaggageCode
        }))
      });
    }
  }
  return {
    calcTourPackageRequests,
    calcOnlyHotelPackageRequests,
    calcOnlyAviaPackageRequests,
    ...payload
  };
};
