import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmComponent } from '../confirm/confirm.component';
import { DynamicModalModule } from '../dynamic-modal/dynamic-modal.module';
import { ConfirmService } from './confirm.service';
import { TranslateModule } from '@ngx-translate/core';
import { PortalModule } from '@angular/cdk/portal';

@NgModule({
  declarations: [ConfirmComponent],
  imports: [CommonModule, DynamicModalModule, MatIconModule, TranslateModule, PortalModule],
  providers: [ConfirmService]
})
export class ConfirmModule {}
