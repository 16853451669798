import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CheckoutFormIn,
  CheckoutFormOut,
  GetReservationDetailsRequest,
  GetReservationDetailsResponse
} from '@appTypes/api.types';
import { environment } from '@env/environment';

@Injectable()
export class CheckoutApiService {
  private readonly apiEndPoint: string = environment.urls.api + '/Checkout/';
  private readonly reservationsEndpoint: string = environment.urls.api + '/Reservations/';

  constructor(private httpClient: HttpClient) {}

  public getCheckoutFormData(payload: CheckoutFormIn) {
    return this.httpClient.post<CheckoutFormOut>(this.apiEndPoint + 'CalcCheckout', payload);
  }

  public confirmCheckout(payload: CheckoutFormIn) {
    return this.httpClient.post<CheckoutFormOut>(this.apiEndPoint + 'ConfirmCheckout', payload);
  }

  public getReservationDetails(payload: GetReservationDetailsRequest) {
    return this.httpClient.post<GetReservationDetailsResponse>(
      this.reservationsEndpoint + 'GetReservationDetails',
      payload
    );
  }
}
